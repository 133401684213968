var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"timeline",style:({
      height: (_vm.headerHeight + (_vm.rowHeight * _vm.timeline.rowCount) + 10) + 'px', 
    })},[_vm._l((_vm.timeline.spans),function(span,period,index){return _c('div',{staticClass:"header",style:({
        left: (_vm.width * index) + 'px',
        width: _vm.width + 'px',
        height: _vm.headerHeight - 20 + 'px', 
      })},[_vm._v(_vm._s(_vm._f("formatAccountingPeriod")(period)))])}),_vm._l((_vm.timeline.members),function(member){return _c('div',{staticClass:"member",style:({
        top: ((_vm.rowHeight * member.rowIdx) + _vm.headerHeight) + 'px',
        left: (_vm.width * member.spanIdx) + 5 + 'px',
        width: (_vm.width * member.spanCount) - 10 + 'px',
        height: _vm.rowHeight - 10  + 'px',
      })},[_vm._v(_vm._s(member.TransactionType)),_c('br'),_vm._v(_vm._s(_vm._f("formatAccountingPeriod")(member.FirstValidAccountingPeriodID))+" - "+_vm._s(_vm._f("formatAccountingPeriod")(member.LastValidAccountingPeriodID))),_c('br'),_vm._v(_vm._s(member.TransactionReaccountRule.StatementSpec.Beneficiary.FullName)+" - "+_vm._s(_vm._f("percentage")(member.TransactionReaccountRule.SplitRate))+" "),_c('br')])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }